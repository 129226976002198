import React from 'react';
import grassBackground from '../images/grass_background.jpg';
import inviteDetailsImage from '../images/invite_details.png';
import aboutUs from '../images/about_us.png';

const SecondMainContent = () => {
  const handleButtonClick = () => {
    // Focus on the Form component when the button is clicked
    const formElement = document.getElementById('form-section');
    formElement.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div
      className="md:min-h-screen bg-center bg-no-repeat bg-cover py-7 md:pt-20 md:pb-10"
      style={{ backgroundImage: `url(${grassBackground})` }}
    >
      <div className="flex flex-col md:justify-center md:items-center md:h-full gap-5 md:gap-20 md:mx-96 md:flex mx-5">
        <div id='about'>
          <img className='rounded rounded-lg'
            src={aboutUs} // Use invite_details.png as the button image
            alt="About Us"
          />
        </div>
        <div>
          <a
            href="http://www.ecowastenet.com/WestNagev/login.aspx"
            target='_blank'
            className="relative overflow-hidden border-none outline-none focus:outline-none transform scale-100 hover:scale-110 transition-transform"
          >
            <img
              src={inviteDetailsImage} // Use invite_details.png as the button image
              alt="Invite Details"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SecondMainContent;
