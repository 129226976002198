import React from 'react';
import bala from '../images/bala.png';
import details from '../images/details.png';
import topPhoto from '../images/main-bottom-photo.gif';
import Form from './form';

const MainContent = () => {
    return (
        <div className="relative bg-[#106333] md:h-screen" id='form-section'>
            <div className="absolute left-1/4 -translate-x-1/4 md:left-1/2 transform md:-translate-x-1/2 md:mt-[-45px] hidden md:block">
                <img src={topPhoto} alt="Bottom Photo" className="" />
            </div>
            <div className='mx-5 md:hidden'>
                <div className='absolute mt-[-20px] w-11/12 right-4 md:left-1/2 transform md:-translate-x-1/2 md:mt-[-45px] md:w-full'>
                    <img src={topPhoto} alt="Bottom Photo" className="" />
                </div>
            </div>
            <div className='md:hidden p-5 pt-10 pb-7'>
                <div id='details' className='grid place-items-center'>
                    <img src={details} alt="Pic" />
                </div>
                <div id='contactUs' className='bg-white p-5 mt-5'>
                    <Form />
                </div>
            </div>
            <div className="absolute bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2 hidden md:block">
                <div className="flex justify-center items-center gap-32" >
                    <div id="fromDiv" className="w-[800px] relative">
                        <img src={bala} alt="Pic 1" className="" />
                    </div>
                    <div className='absolute left-0 ml-14 mt-28 w-1/3'>
                        <Form />
                    </div>
                    <div id='details'>
                        <img src={details} alt="Pic 2" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainContent;
