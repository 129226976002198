import React from 'react';
import Form from './components/form';
import Header from './components/header';
import Main from './components/main';
import Footer from './components/footer';

const App = () => {
  return (
    <div>
     <Header />
     <Main />
      {/* <div
        className="h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${background2})` }}
      >
        <div className="flex items-center h-screen">
          <div className="ml-60 w-96 p-8">
            <Form/>
          </div>
      </div> */}
      {/* </div> */}
      <Footer />
    </div>
  );
};

export default App;
