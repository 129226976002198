import React from 'react';
import MainTopPhotos from './mainTopPhotos';
import MainContent from './mainContent';
import SecondMainContent from './scondMainContent';

const Main = () => {
  return (
    <main className=''>
        <MainTopPhotos />
        <MainContent />
        <SecondMainContent />
    </main>
  );
};

export default Main;
