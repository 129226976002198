import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const PopupMessage = ({ message, onClose }) => {
  return (
      <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Background covering the entire screen */}
      <div className="bg-black bg-opacity-50 absolute inset-0 rounded-lg"></div>
      <div className="bg-white p-8 rounded-lg z-10">
        <p className="text-lg text-center">{message}</p>
        <button
          onClick={onClose}
          className="block mx-auto mt-4 px-4 py-2 text-white bg-[#106333] rounded-lg"
        >
          OK
        </button>
      </div>
    </div>
  );
};

const Form = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);

  const sendEmail = async (values) => {
    try {
      await emailjs.send('service_xkafiin', 'template_rmfracu', {
        from_name: values.fullName,
        from_mail: values.email,
        phone: values.phone,
        address: values.address,
        city: values.city,
      }, 'kjy25ITkT7re1nbXn');

      setIsMailSent(true);
      setShowPopup(true);
    } catch (error) {
      setIsMailSent(false);
      setShowPopup(true);
      console.error('Failed to send email:', error);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      address: '',
      city: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('יש להזין שם מלא'),
      email: Yup.string().email('מייל לא תקין').required('יש להזין מייל'),
      phone: Yup.string()
        .matches(
          /^(0|(\+972-?))5\d{8}$/,
          'מספר טלפון לא תקין'
        )
        .required('יש להזין טלפון'),
      address: Yup.string().required('יש להזין כתובת'),
      city: Yup.string().required('יש להזין עיר'),
    }),
    onSubmit: async (values, { resetForm }) => {
      await sendEmail(values);
      resetForm();
      console.log(values);
    },
  });

  return (
    <div className="">
      <form onSubmit={formik.handleSubmit} className="" >
        <div className="mb-4">
          <input
            id="fullName"
            name="fullName"
            type="text"
            placeholder="שם מלא"
            className="w-full border border-gray-300 p-2 rounded bg-[#dbf1d1] text-right"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fullName}
          />
          {formik.touched.fullName && formik.errors.fullName ? (
            <div className="text-red-500 text-right">{formik.errors.fullName}</div>
          ) : null}
        </div>

        <div className="mb-4">
          <input
            id="email"
            name="email"
            type="email"
            placeholder="מייל"
            className="w-full border border-gray-300 p-2 rounded bg-[#dbf1d1] text-right"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-right">{formik.errors.email}</div>
          ) : null}
        </div>

        <div className="mb-4">
          <input
            id="phone"
            name="phone"
            type="text"
            placeholder="טלפון"
            className="w-full border border-gray-300 p-2 rounded bg-[#dbf1d1] text-right"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="text-red-500 text-right">{formik.errors.phone}</div>
          ) : null}
        </div>

        <div className="mb-4">
        <input
            id="city"
            name="city"
            type="text"
            placeholder="עיר"
            className="w-full border border-gray-300 p-2 rounded bg-[#dbf1d1] text-right"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
          />
         {formik.touched.city && formik.errors.city ? (
            <div className="text-red-500 text-right">{formik.errors.city}</div>
          ) : null}
          
        </div>

        <div className="mb-4">
        <input
            id="address"
            name="address"
            type="text"
            placeholder="כתובת"
            className="w-full border border-gray-300 p-2 rounded bg-[#dbf1d1] text-right"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="text-red-500 text-right">{formik.errors.address}</div>
          ) : null}
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-[#106333] hover:bg-[#0a4629] text-white font-bold py-2 px-4 rounded"
          >
            שלח
          </button>
        </div>
      </form>

      {/* Popup message */}
      {showPopup && (
        <PopupMessage
          message={isMailSent ? '!מייל נשלח בהצלחה' : 'משהו השתבש, מייל לא נשלח'}
          onClose={closePopup}
        />
      )}
    </div>
  );
};

export default Form;
