import React, { useState } from 'react';
import headerBackground from '../images/laye12.jpg';
import logo1 from '../images/logo-1.png';
import logo2 from '../images/protection-ministry.png';
import logo3 from '../images/west-negev.png';
import HeaderContent from './headerContent';
import giveTav from '../images/give_tav.png';
import priceImage from '../images/price.png';
import FixedHeader from './fixedHeader';


const Header = () => {
  const logoHeight = "100px"; // Replace with your desired logo height

  // ... (other code)

  return (
    <div className="relative">
      <img src={headerBackground} alt="Header Background" className="w-full h-auto" />
      <div className="absolute top-0 left-0 right-0 m-5 md:m-10">
        {/* Logos and other content */}
        <FixedHeader />
        <div className="flex items-center justify-between hidden md:block">
          <div className="flex items-center space-x-2">
            <img src={logo1} alt="Logo 1" className={`max-h-${logoHeight}`} />
            <img src={logo2} alt="Logo 2" className={`max-h-${logoHeight}`} />
            <a href="https://www.westnegev.org.il/" target='_blank'>
              <img src={logo3} alt="Logo 3" className={`h-${logoHeight}`} />
            </a>
            {/* <a href="https://givetav.com/" className='pl-10' target='_blank'>
              <img src={giveTav} alt="Logo 3" className={`h-${logoHeight} rounded rounded-lg`} />
            </a> */}
            {/* You can add the fourth logo image here */}
          </div>
          {/* ... (other code) */}
        </div>
        <HeaderContent />
        <div className='relative'>
          <div className='w-20 top-[-20px] right-[-10px] xl:w-52 xl:top-[-20px] 2xl:right-10 2xl:top-12 absolute'>
            <img src={priceImage} alt="Logo 1" />
          </div>
          {/* ... (other code) */}
        </div>
      </div>
    </div>
  );
};

export default Header;
