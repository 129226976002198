import React from 'react';
import Metgo from '../images/metgo.png';

const Footer = () => {

  return (
    <footer className="bg-[#106333] px-14 flex justify-center items-center">
      {/* Left item - Logo */}
      <a
        href="https://metgo.app.link/muLDPWMbPtb"
        target="_blank"
        rel="noopener noreferrer"
        className="text-white focus:outline-none"
        title="Metgo"
      >
        <img src={Metgo} className="max-h-24" alt="Metgo" />
      </a>
      {/* Right item - Links */}
    </footer>
  );
};

export default Footer;
