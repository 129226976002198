import React from 'react';
import img1 from '../images/TITLE1.gif';
import img2 from '../images/TITLE2.gif';
import Instagram from '../images/instagram.png';
import Facebook from '../images/facebook.png';
import Phone from '../images/telephone.png';

const HeaderContent = () => {
  return (
    <div className="flex justify-center md:mt-12 relative">
      {/* Centered images */}
      <div className="flex flex-col items-center space-y-2 w-3/5 md:w-2/5">
        <img src={img1} alt="Image 1" />
        <img src={img2} alt="Image 2" />
      </div>

      {/* Icons on the right */}
      <div className="absolute right-0 top-0 flex-col items-end space-y-4 mr-20 mt-4 hidden md:flex">
        {/* Icon 1: Phone */}
        <a href="tel:*8261" className="icon-link" title="*8261">
          <img src={Phone} alt="Icon" />
        </a>

        {/* Icon 2: Facebook */}
        <a
          href="https://www.facebook.com/ba.la.eshkol.negev/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-link"
          title='Facebook'
        >
          <img src={Facebook} alt="Icon" />
        </a>

        {/* Icon 3: Instagram */}
        <a href="https://instagram.com/ba.la.eshkol"
          target="_blank"
          rel="noopener noreferrer"
          className="icon-link"
          title='Instagram'>
          <img src={Instagram} alt="Icon" />
        </a>

        {/* You can add more icons here */}
      </div>
    </div>
  );
};

export default HeaderContent;
