import React from 'react';
import mainBackground from '../images/main-background.jpg';
import centeredPhoto from '../images/main-centered-photo.png';


const MainTopPhotos = () => {
  return (
    <div className="relative">
      {/* Main Background */}
      <div className="bg-cover bg-center md: h-20 md:h-44" style={{ backgroundImage: `url(${mainBackground})` }}>
        {/* Centered Photo */}
        <div className="flex justify-center items-center pt-5">
          <img src={centeredPhoto} alt="Centered Photo" className="w-5/6 md:w-1/2" />
        </div>
      </div>

    </div>
  );
};

export default MainTopPhotos;
