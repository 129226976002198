import React, { useState, useEffect } from 'react';
import Instagram from '../images/instagram.png';
import Facebook from '../images/facebook.png';
import LinkedIn from '../images/linkedin.png';
import Phone from '../images/phone.png';
import Mail from '../images/mail.png';

const FixedHeader = () => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`block md:hidden z-10 bg-black bg-opacity-50 ${isSticky ? 'fixed top-0 left-0 right-0' : ''}`}>
            <nav className="flex    ">
                <div className="flex-1 flex items-center justify-end px-4 py-2 bg-gray-800 text-white hover:bg-gray-900 transition duration-300 ease-in-out">
                    <div className="flex gap-4">
                        <a
                            href="https://www.facebook.com/ba.la.eshkol.negev/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Facebook"
                        >
                            <div className="bg-white rounded-full">
                                <img src={Facebook} alt="Facebook" />
                            </div>
                        </a>
                        <a
                            href="https://instagram.com/ba.la.eshkol"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Instagram"
                        >
                            <div className="bg-white rounded-full">
                                <img src={Instagram} alt="Instagram" />
                            </div>
                        </a>
                        <a
                            href="tel:*8261"
                            title="Phone"
                        >
                            <div className=" text-center">
                                <img src={Phone} alt="Phone" />
                            </div>
                        </a>
                        <a
                            href="mailto:bala@westnegev.org.il"
                            title="Email"
                        >
                            <div className="text-center">
                                <div className="text-center">
                                    <img src={Mail} alt="Mail" />
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className=" text-white ease-in-out">

                    </div>
                    <div className=" text-white ease-in-out ml-auto">
                        <a
                            href="#contactUs"
                            className="text-white ease-in-out ml-auto cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default jump behavior
                                const section = document.getElementById('contactUs');
                                if (section) {
                                    window.scrollTo({
                                        behavior: 'smooth',
                                        top: section.offsetTop,
                                    });
                                }
                            }}
                        >
                            הזמן עכשיו
                        </a>
                    </div>

                </div>
            </nav>

        </div>
        // <div className="md:hidden">
        //     <button
        //         onClick={toggleMenu}
        //         className="fixed top-4 left-4 z-10 p-2 rounded-md text-gray-600 hover:text-gray-800 focus:outline-none"
        //     >
        //         <svg
        //             xmlns="http://www.w3.org/2000/svg"
        //             className="h-8 w-8"
        //             viewBox="0 0 20 20"
        //             fill="currentColor"
        //         >
        //             <path
        //                 fillRule="evenodd"
        //                 d="M3 4.25a.75.75 0 000 1.5h14a.75.75 0 000-1.5H3zm0 6a.75.75 0 000 1.5h14a.75.75 0 000-1.5H3zm0 5.75a.75.75 0 100 1.5h14a.75.75 0 100-1.5H3z"
        //                 clipRule="evenodd"
        //             />
        //         </svg>
        //     </button>

        //     <div
        //         className={`fixed top-0 left-0 h-screen w-1/3 transition-transform transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        //             } bg-white z-20`}
        //     >
        //         <button
        //             onClick={toggleMenu}
        //             className="absolute top-4 right-4 z-10 p-2 rounded-md text-gray-600 hover:text-gray-800 focus:outline-none"
        //         >
        //             <svg
        //                 xmlns="http://www.w3.org/2000/svg"
        //                 className="h-6 w-6"
        //                 viewBox="0 0 20 20"
        //                 fill="currentColor"
        //             >
        //                 <path
        //                     fillRule="evenodd"
        //                     d="M13.364 10l3.293-3.293a1 1 0 00-1.414-1.414L12.95 8.586 9.657 5.293a1 1 0 00-1.414 1.414L11.536 10l-3.293 3.293a1 1 0 101.414 1.414L12.95 11.414l3.293 3.293a1 1 0 101.414-1.414L13.364 10z"
        //                     clipRule="evenodd"
        //                 />
        //             </svg>
        //         </button>

        //         <ul className="pt-16 pl-4 space-y-4">
        //             <li>
        //                 <a
        //                     href="#contactUs"
        //                     className="text-gray-700 hover:text-gray-900"
        //                     onClick={(e) => handleSectionClick('contactUs', e)}
        //                 >
        //                     Contact Us
        //                 </a>
        //             </li>
        //             <li>
        //                 <a
        //                     href="#about"
        //                     className="text-gray-700 hover:text-gray-900"
        //                     onClick={(e) => handleSectionClick('about', e)}
        //                 >
        //                     About
        //                 </a>
        //             </li>
        //             <li>
        //                 <a
        //                     href="#blog"
        //                     className="text-gray-700 hover:text-gray-900"
        //                     onClick={(e) => handleSectionClick('blog', e)}
        //                 >
        //                     Blog
        //                 </a>
        //             </li>
        //         </ul>
        //     </div>

        //     <div
        //         className={`fixed top-0 left-0 h-screen w-full transition-opacity ${isMenuOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'
        //             } bg-black z-10`}
        //         onClick={toggleMenu}
        //     />
        // </div>
    );
};

export default FixedHeader;
